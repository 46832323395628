import { LOCAL_VAR } from './../../../core/constants';
import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DealsService } from '@app/core/service/deals.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { Router } from '@angular/router';

@Component({
  selector: 'app-request-mail',
  templateUrl: './request-mail.component.html',
  styleUrls: ['./request-mail.component.scss']
})
export class RequestMailComponent implements OnInit {
  @Input()
  dealId? = '';

  @Input()
  dealerEmail? = '';

  @Input()
  signator? = '';

  @Input()
  contactList? = '';
  contacts:any=['Primary Contact'];

  @Input()
  pdfs? = '';


  agent: any = null;
  requestForm: FormGroup;
  isLoading = false;
  constructor(
    public activeModal: NgbActiveModal,
    private dealsService: DealsService,
    private toastrService: ToastrService,
    private formBuilder: FormBuilder,
    public router: Router
  ) {
    this.agent = localStorage.getItem(LOCAL_VAR.selectedAgent);
    this.agent = JSON.parse(this.agent);
  }

  ngOnInit() {    
    this.createForm();
  }

  createForm() {
    this.requestForm = this.formBuilder.group({
      // dealerEmail: [this.dealerEmail, [
      //   Validators.required,
      //   Validators.pattern(/^(\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3}))$/)
      // ]]
      signator: [this.signator, [
        Validators.required
      ]]
    });
    if(this.contactList!=''){
      this.contacts=JSON.parse(this.contactList);
    }
  }

  rejectRequest() {
    this.isLoading=true;
    // let payload={dealerEmail:this.requestForm.value.dealerEmail,pdfs:this.pdfs};
    let payload={signator:this.requestForm.value.signator,pdfs:this.pdfs};
      this.dealsService
        .sendPDF(this.dealId,payload)
        .subscribe((response: any) => {
          this.isLoading=false;
          if (response) {
            this.activeModal.close(response);
          } else {
            this.toastrService.warning('something went Wrong');
          }
        });
    }
}
